import React from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';
import classNames from 'classnames';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import withCookies from 'HOC/withCookies';

import Calendar from 'Containers/common/calendar';

import { BookNowTravelLaterBanner } from 'Components/common/bookNowTravelLaterBanner';
import Conditional from 'Components/common/conditional';
import LSpan from 'Components/common/localizedTags/localizedSpan';
import ContentBookButton from 'Components/desktop/contentBookButton';

import { DropdownArrowSolidSvg } from 'Assets/svg/productPage';

import {
	checkIsMicroband,
	getExperiencePageEventCommonProperties,
	trackDateSelected,
	trackEvent,
} from 'Utils/analytics';
import {
	getAnalyticsFlowType,
	getAvailableToursCount,
	isExternalSeatmapProduct,
	isTourGroupOpenDated,
} from 'Utils/bookingFlowUtils';
import { computeDaysTillExperience, formatUsingDayJS } from 'Utils/dateUtils';
import { getDateWeekRange } from 'Utils/pricingUtils';
import {
	getCashbackAmount,
	getTourCityCode,
	hasComboVariants,
	isLttCollectionId,
} from 'Utils/productUtils';
import {
	getBooking,
	getCalendarInventories,
	getCitiesMap,
	getDomainConfig,
	getHost,
	getPricing,
	getUser,
} from 'Utils/stateUtils';

import { fetchPricingForRange } from 'Thunks/pricing';
import { setBookingDate, setBookingTime } from 'Actions/booking';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { ANALYTICS_FLOW_TYPE, BOOKING_FLOW_TYPE } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';

const CashbackSliver = dynamic(
	() =>
		import(
			/* webchunkName: 'CashbackSliver' */ 'Components/desktop/product/cashbackSliver'
		),
);
const TourBasicPriceWrapper = dynamic(
	() =>
		import(
			/* webchunkName: 'TourBasicPriceWrapper' */ 'Components/desktop/tourBasicPriceWrapper'
		),
);

const BookNowCard = styled.div`
	font-family: ${fonts.HALYARD.TEXT};
	display: flex;
	flex-direction: column;
	position: relative;
	border: 1px solid ${colors.GREY.E2};
	border-radius: 1rem;
	background: ${colors.WHITE};

	.date-selection-wrapper {
		overflow: hidden;
		height: 3rem;
		border: 1px solid ${colors.GREY.E2};
		border-radius: 0.75rem;
		margin-top: 1.375rem;
		display: flex;
		width: 100%;
		align-items: center;
		cursor: pointer;
		justify-content: space-between;

		:hover {
			box-shadow: 0 3px 4px ${colors.GREY.LIGHTEST};
		}

		:focus {
			outline: none;
		}

		&.is-disabled {
			cursor: not-allowed;

			:hover {
				box-shadow: none;
			}

			.date-selection-button {
				cursor: not-allowed;
			}
		}

		.date-selection-button {
			align-items: center;
			color: ${colors.GREY['44']};
			font-size: 0.9375rem;
			line-height: 0.9375rem;
			cursor: pointer;
			padding: 0 0.9375rem;
		}
	}

	.dropdown-div {
		width: 2.125rem;
		height: 100%;
		background: ${colors.GREY.G7};
		display: flex;
		align-items: center;
		justify-content: center;

		.dropdown-svg {
			width: 0.75rem;
			height: 0.75rem;
			fill: ${colors.GREY['66']};
		}
	}
`;

const BookerBottomWrapper = styled.div`
	padding: 1.125rem 1.25rem;
`;

type BookButtonCardElementsProps = {
	product?: any;
	pricing?: any;
	booking?: any;
	lang?: string;
	onDateChange?: (...args: any[]) => any;
	onTimeChange?: (...args: any[]) => any;
	onFetchPricing?: (...args: any[]) => any;
	resetBookingTime: (...args: any[]) => any;
	citiesMap?: any;
	user?: any;
	cookies?: any;
	inventory?: any;
	host: string;
	showCashbackInfo?: boolean;
	isCalendarOpen?: boolean;
	scrollToVariantSection?: (...args: any[]) => any;
	isButtonLoading?: boolean;
	setIsButtonLoading: (state: boolean) => void;
	mbName?: string;
	getInventoryData?: (...args: any[]) => any;
};

type onFetchPricingArgs = {
	id: string | number;
	lang: string;
	selectedDate: string;
};

type BookButtonCardElementsState = any;

class BookButtonCardElements extends React.Component<
	BookButtonCardElementsProps,
	BookButtonCardElementsState
> {
	state = {
		isGetTicketsClicked: false,
		isTooltipOpen: false,
		isCashbackModalOpen: false,
	};

	componentDidUpdate(prevProps: BookButtonCardElementsProps) {
		const {
			product,
			pricing,
			booking,
			citiesMap,
			mbName,
			getInventoryData,
		} = this.props;
		const { booking: prevBooking } = prevProps;
		if (booking && prevBooking) {
			const { id, selectedDate: date, selectedTourId: tourId } = booking;
			const { selectedDate: prevDate } = prevBooking;
			if (pricing && date && date !== prevDate) {
				const timeZone =
					citiesMap?.[getTourCityCode(product)]?.timeZone;
				const flowType = getAnalyticsFlowType(product);
				const inventoryData = getInventoryData?.(tourId);
				const availableToursCount =
					flowType === ANALYTICS_FLOW_TYPE.MULTI_TOUR ||
					flowType === ANALYTICS_FLOW_TYPE.SINGLE_TOUR
						? getAvailableToursCount(inventoryData, date)
						: null;

				trackDateSelected({
					date,
					flowType,
					tgid: id,
					leadingDays: computeDaysTillExperience(date, timeZone),
					availableToursCount,
					[ANALYTICS_PROPERTIES.MB_NAME]: checkIsMicroband(mbName),
				});
			}
		}
	}

	onClickDateSelector = () => {
		// @ts-expect-error TS(2339): Property 'showDateSelector' does not exist on type... Remove this comment to see the full error message
		const { showDateSelector } = this.props;
		this.setState({ isGetTicketsClicked: false });
		showDateSelector();
	};

	onClickGetTickets = () => {
		// @ts-expect-error TS(2339): Property 'showDateSelector' does not exist on type... Remove this comment to see the full error message
		const { showDateSelector } = this.props;
		this.setState({ isGetTicketsClicked: true });
		showDateSelector();
	};

	trackDateSelected = ({ id, date, tourId }: any) => {
		const { citiesMap, product, mbName, getInventoryData } = this.props;
		const timeZone = citiesMap?.[getTourCityCode(product)]?.timeZone;
		const inventoryData = getInventoryData?.(tourId);
		const flowType = getAnalyticsFlowType(product);
		const availableToursCount =
			flowType === ANALYTICS_FLOW_TYPE.MULTI_TOUR ||
			flowType === ANALYTICS_FLOW_TYPE.SINGLE_TOUR
				? getAvailableToursCount(inventoryData, date)
				: null;

		trackDateSelected({
			tourId,
			tgid: id,
			date,
			leadingDays: computeDaysTillExperience(date, timeZone),
			availableToursCount,
			[ANALYTICS_PROPERTIES.MB_NAME]: checkIsMicroband(mbName),
		});
	};

	getBookingCalendarHTML = () => {
		const {
			// @ts-expect-error TS(2339): Property 'showDateSelector' does not exist on type... Remove this comment to see the full error message
			showDateSelector,
			product,
			onTimeChange,
			onFetchPricing,
			resetBookingTime,
		} = this.props;
		const { isGetTicketsClicked } = this.state;
		const isSeatmap =
			product?.flowType === BOOKING_FLOW_TYPE.SEATMAP ||
			isExternalSeatmapProduct(product);
		const isLtt = isLttCollectionId(product);

		return (
			<Calendar
				id={String(product?.id)}
				trackDateSelected={this.trackDateSelected}
				onClose={showDateSelector}
				changeQueryOnSelection={false}
				trackCalendarDateSelected={!isSeatmap}
				onTimeChange={onTimeChange}
				isGetTicketsClicked={isGetTicketsClicked}
				useGetTicketsClicked={!isSeatmap}
				showTimeSlots={isSeatmap}
				onFetchPricing={onFetchPricing}
				isSeatmap={isSeatmap}
				afterDateChange={() => {
					resetBookingTime({ id: String(product?.id) });
				}}
				isLtt={isLtt}
				excludedClassesForClosingCalendar={[
					'date-selection-wrapper',
					'date-selection-button',
					'dropdown-div',
					'dropdown-svg',
				]}
			/>
		);
	};

	getDateSelector = () => {
		const { booking, pricing } = this.props;
		let dateSelected,
			isDisabled = false;

		if (pricing) {
			const { inventoryMap } = pricing;
			isDisabled = inventoryMap
				? !Object.keys(inventoryMap).length
				: false;
		}
		if (booking) {
			const { selectedDate } = booking;
			dateSelected = selectedDate;
		}
		const text = dateSelected
			? formatUsingDayJS(dateSelected, 'ddd, D MMM YYYY')
			: strings.BBC_CTA_SELECT_DATE;
		const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
			if (!isDisabled && e.key === 'Enter') this.onClickDateSelector();
		};
		return (
			<div
				className={classNames('date-selection-wrapper', {
					'is-disabled': isDisabled,
				})}
				onClick={!isDisabled ? this.onClickDateSelector : () => {}}
				onKeyDown={handleKeyPress}
				role='button'
				tabIndex={0}
			>
				<LSpan className='date-selection-button'>{text}</LSpan>
				<div className='dropdown-div'>{this.dropdownArrowSolid}</div>
			</div>
		);
	};

	trackCashbackPopupOpened = () => {
		trackEvent({
			eventName: 'Tooltip Viewed',
			[ANALYTICS_PROPERTIES.TOOLTIP_TYPE]: 'cashback',
			...getExperiencePageEventCommonProperties(this.props.product),
		});
	};

	dropdownArrowSolid = (<DropdownArrowSolidSvg className='dropdown-svg' />);

	render() {
		const {
			product,
			lang,
			scrollToVariantSection,
			isCalendarOpen,
			showCashbackInfo,
			isButtonLoading,
			setIsButtonLoading,
		} = this.props;
		const { listingPrice, flowType } = product;
		const isInventoryAvailable = !!listingPrice;
		const cashbackAmount = getCashbackAmount(product, lang);

		return (
			<>
				<BookNowCard>
					<BookerBottomWrapper>
						<Conditional if={isInventoryAvailable}>
							<TourBasicPriceWrapper product={product} />
						</Conditional>

						<Conditional
							if={
								flowType &&
								flowType !== BOOKING_FLOW_TYPE.SEATMAP &&
								flowType &&
								flowType !==
									BOOKING_FLOW_TYPE.PRIVATE_AIRPORT_TRANSFER &&
								isInventoryAvailable &&
								!hasComboVariants(product) &&
								!isTourGroupOpenDated(product)
							}
						>
							{this.getDateSelector()}
						</Conditional>

						<Conditional if={isTourGroupOpenDated(product)}>
							<BookNowTravelLaterBanner />
						</Conditional>

						{isCalendarOpen ? this.getBookingCalendarHTML() : null}

						<ContentBookButton
							isButtonLoading={isButtonLoading}
							setIsButtonLoading={setIsButtonLoading}
							openDateCalendar={this.onClickGetTickets}
							scrollToVariantSection={scrollToVariantSection}
						/>
					</BookerBottomWrapper>
				</BookNowCard>

				<Conditional if={showCashbackInfo && !!cashbackAmount}>
					<CashbackSliver
						cashback={cashbackAmount!}
						onSliverClicked={this.trackCashbackPopupOpened}
					/>
				</Conditional>
			</>
		);
	}
}

const mapStateToProps = (state: any, ownProps: any) => {
	const id = ownProps.router.query.id;
	const { name, showCashbackInfo } = getDomainConfig(state);
	const getInventoryData = (tourId: any) =>
		getCalendarInventories(state, id, tourId);

	return {
		booking: getBooking(state, id),
		pricing: getPricing(state, id),
		citiesMap: getCitiesMap(state),
		user: getUser(state),
		host: getHost(state),
		mbName: name,
		showCashbackInfo,
		getInventoryData,
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
	onDateChange({ id, date }) {
		dispatch(setBookingDate({ id, date }));
	},
	onTimeChange({ id, time, defaultSelection }: any) {
		dispatch(setBookingTime({ id, time, defaultSelection }));
	},
	onFetchPricing({ id, lang, selectedDate }: onFetchPricingArgs) {
		const dateRange = getDateWeekRange({ selectedDate }) ?? {};
		return dispatch(
			fetchPricingForRange({
				productId: id,
				lang: lang,
				selectedDate,
				flushExistingInventories: true,
				...dateRange,
			}),
		);
	},
	resetBookingTime({ id }: { id: string }) {
		dispatch(setBookingTime({ id, time: null }));
	},
});

const BookButtonCard = withRouter(
	// @ts-expect-error TS(2345): Argument of type 'typeof (Anonymous class)' is not... Remove this comment to see the full error message
	withCookies(
		connect(mapStateToProps, mapDispatchToProps)(BookButtonCardElements),
	),
);

export default BookButtonCard;
