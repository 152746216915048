import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import noop from 'lodash/noop';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import { Button } from '@headout/eevee';

import {
	getExperiencePageEventCommonProperties,
	trackEvent,
} from 'Utils/analytics';
import {
	isExternalSeatmapProduct,
	isTourGroupOpenDated,
	routeToBookingFlow,
} from 'Utils/bookingFlowUtils';
import {
	checkIfMultiVariantComboProduct,
	hasComboVariants,
	hasMultipleAvailableVariants,
} from 'Utils/productUtils';
import {
	getBooking,
	getCalendarInventories,
	getProduct,
} from 'Utils/stateUtils';

import { ANALYTICS_FLOW_TYPE, BOOKING_FLOW_TYPE } from 'Constants/constants';
import { strings } from 'Constants/strings';

const BookNowButtonContainer = styled.div`
	margin-top: 1rem;
	width: 100%;
`;

type ContentBookButtonProps = {
	product?: any;
	booking: any;
	inventoryData?: any;
	openDateCalendar: (...args: any[]) => any;
	scrollToVariantSection?: (...args: any[]) => any;
	lang?: any;
	isButtonLoading?: boolean;
	setIsButtonLoading: (state: boolean) => void;
};

const ContentBookButton = ({
	product,
	booking,
	openDateCalendar,
	lang,
	scrollToVariantSection,
	inventoryData,
	isButtonLoading,
	setIsButtonLoading,
}: ContentBookButtonProps) => {
	const [isButtonWaitingForInventory, setIsButtonWaitingForInventory] =
		useState(!!inventoryData);

	useEffect(() => {
		const reloadPersistedPage = (event: PageTransitionEvent) => {
			if (event.persisted) {
				setIsButtonLoading(false);
			}
		};
		window.addEventListener('pageshow', reloadPersistedPage);
		return () => {
			window.removeEventListener('pageshow', reloadPersistedPage);
		};
	}, [setIsButtonLoading]);

	useEffect(() => {
		if (isButtonWaitingForInventory && inventoryData) {
			setIsButtonLoading(false);
			setIsButtonWaitingForInventory(false);
		}
	}, [isButtonWaitingForInventory, inventoryData]);

	if (!booking) return null;

	const { id, listingPrice } = product;
	const { selectedDate, selectedVariantId } = booking;
	const { sortedInventoryDates } = inventoryData ?? {};

	let isProductAvailable = Boolean(listingPrice);
	if (sortedInventoryDates) {
		isProductAvailable =
			Boolean(listingPrice) && sortedInventoryDates?.length;
	}

	const isMultiVariantComboProduct = checkIfMultiVariantComboProduct(product);
	const isOpenDated = isTourGroupOpenDated(product);

	const isPrivateAirportTransferFlow =
		product?.flowType === BOOKING_FLOW_TYPE.PRIVATE_AIRPORT_TRANSFER;

	const onClick = () => {
		let flowType;
		if (
			!(isMultiVariantComboProduct && !selectedVariantId) &&
			!inventoryData
		) {
			setIsButtonLoading(true);
			setIsButtonWaitingForInventory(true);
			return;
		}
		if (isOpenDated) {
			flowType = hasMultipleAvailableVariants(product)
				? ANALYTICS_FLOW_TYPE.OPEN_DATED_MULTI_VARIANT
				: ANALYTICS_FLOW_TYPE.OPEN_DATED_NO_VARIANT;
		}
		if (!isMultiVariantComboProduct)
			trackEvent({
				eventName: 'Check Availability Clicked',
				'Experience Date': selectedDate,
				...getExperiencePageEventCommonProperties(product),
				...(isOpenDated && { 'Flow Type': flowType }),
			});
		if (
			isMultiVariantComboProduct &&
			!selectedVariantId &&
			scrollToVariantSection
		) {
			scrollToVariantSection();
		} else {
			setIsButtonLoading(true);
			goToNextStage();
		}
	};

	const getCTAText = () => {
		switch (true) {
			case isOpenDated && !hasComboVariants(product):
				return strings.CBB_BOOK_NOW;
			case !isProductAvailable:
				return strings.CMN_UNAVAILABLE;
			case isMultiVariantComboProduct && !selectedVariantId:
				return strings.CBB_CHECK_AVAIL;
			default:
				return isMultiVariantComboProduct
					? strings.NEXT
					: strings.CBB_CHECK_AVAIL;
		}
	};

	const goToNextStage = () => {
		try {
			if (inventoryData) {
				const isRouteToBookingFlow =
					(selectedDate ||
						(isMultiVariantComboProduct && selectedVariantId) ||
						(!isMultiVariantComboProduct &&
							hasComboVariants(product)) ||
						isOpenDated ||
						isPrivateAirportTransferFlow) &&
					product?.flowType !== BOOKING_FLOW_TYPE.SEATMAP &&
					!isExternalSeatmapProduct(product);
				if (isRouteToBookingFlow) {
					setIsButtonLoading(true);
					routeToBookingFlow(
						id,
						booking,
						{
							lang,
							variantId: selectedVariantId,
						},
						true,
						product,
					);
				} else {
					openDateCalendar();
					setIsButtonLoading(false);
				}
			}
		} catch (_err) {
			setIsButtonLoading(false);
		}
	};

	const isLoading = isButtonLoading || isButtonWaitingForInventory;
	const isDisabled =
		!isProductAvailable || isButtonLoading || isButtonWaitingForInventory;
	const buttonState = isLoading
		? 'loading'
		: isDisabled
		? 'disabled'
		: 'default';

	return (
		<BookNowButtonContainer>
			<Button
				as='button'
				size='medium'
				btnType='primary'
				variant='primary'
				primaryText={getCTAText()}
				data-qa-marker='tour-CTA-button'
				onClick={isProductAvailable ? onClick : noop}
				state={buttonState}
			/>
		</BookNowButtonContainer>
	);
};

const mapStateToProps = (state: any, ownProps: any) => {
	const { id, lang } = ownProps.router.query;
	return {
		product: getProduct(state, id),
		booking: getBooking(state, id),
		inventoryData: getCalendarInventories(state, id),
		location: ownProps.router,
		lang,
	};
};

export default withRouter(connect(mapStateToProps)(ContentBookButton));
