import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

interface TStyledWrapper {
	isCheckoutPage: boolean;
}

const Wrapper = styled.div<TStyledWrapper>`
	display: flex;
	padding: 1rem 1.25rem;
	margin: ${({ isCheckoutPage }) =>
		isCheckoutPage ? '0.75rem 0 1.5rem' : '1rem -1.25rem 1.25rem'};
	background: linear-gradient(
		90deg,
		rgba(255, 242, 204, 0.6) 0%,
		rgba(255, 242, 204, 0) 100%,
		rgba(255, 242, 204, 0) 100%
	);

	.logo-wrapper {
		margin-right: 0.75rem;
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)};
	}

	.content {
		width: 100%;

		.header {
			${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_LARGE)};
			color: ${colors.BANNER_ORANGE_TEXT};
		}

		.desc {
			margin-top: 0.25rem;
			${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_REGULAR)};
			color: ${colors.GREY.G3};

			.validity {
				font-weight: 500;
			}
		}
	}
	@media (max-width: 768px) {
		margin: ${({ isCheckoutPage }) =>
			isCheckoutPage ? '0.75rem 0 1.5rem' : '1rem -24px 1.25rem'};

		.content {
			.desc {
				width: 298px;
			}
		}
	}
`;

export const BookNowTravelLaterBanner = ({
	validity,
	isCheckoutPage,
	className,
}: any) => (
	<Wrapper isCheckoutPage={isCheckoutPage} className={className}>
		<div className='logo-wrapper'>😇</div>
		<div className='content'>
			<div className='header'>
				{strings.BOOK_NOW_GO_LATER_BANNER.HEADER}
			</div>
			<div className='desc'>
				<Conditional if={validity}>
					{strings.BOOK_NOW_GO_LATER_BANNER.DESCRIPTION.WITH_VALIDITY}
					<span className='validity'> {validity}</span>
				</Conditional>

				<Conditional if={!validity}>
					{
						strings.BOOK_NOW_GO_LATER_BANNER.DESCRIPTION
							.WITHOUT_VALIDITY
					}
				</Conditional>
			</div>
		</div>
	</Wrapper>
);
